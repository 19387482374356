import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AuthorizedLayout from '@/layouts/AuthorizedLayout.vue'
import UnAuthorizedLayout from '@/layouts/UnAuthorizedLayout.vue'
import HomeView from '@/views/Home/App.vue'
import Login from '@/views/Login/App.vue'
import CompaniesView from '@/views/Company/CompaniesView.vue'
import EmployerDetailView from '@/views/dict/Employers/Control.vue'
import EmployersView from '@/views/dict/Employers/List.vue'
import PhotoView from '@/views/Photo/Photo.vue'
import HelpView from '@/views/Help/Help.vue'
import CompanyView from '@/views/Company/Company.vue'
import CheckinsView from '@/views/Checkins/Checkins.vue'
import DashboardView from '@/views/Dashboard/Dashboard.vue'
import RisksView from '@/views/Risks/Risks.vue'
import NotFoundView from '@/views/NotFound/App.vue'
import StubView from '@/views/Stub/App.vue'

import CompanyCardsView from '@/views/dict/CompanyCards/List.vue'
import CompanyCardDetailView from '@/views/dict/CompanyCards/Control.vue'
import CamerasView from '@/views/dict/Camera/Cameras.vue'
import CameraDialog from '@/views/dict/Camera/CameraDialog.vue'
import CameraDetailView from '@/views/dict/Camera/CameraDetailView.vue'
import LocationsView from '@/views/dict/Location/List.vue'
import LocationDetailView from '@/views/dict/Location/Control.vue'
import PaymentMethodsView from '@/views/dict/PaymentMethod/List.vue'
import PaymentMethodDetailView from '@/views/dict/PaymentMethod/Control.vue'
import PostsView from '@/views/dict/Post/List.vue'
import PostDetailView from '@/views/dict/Post/Control.vue'
import PricesView from '@/views/dict/Prices/List.vue'
import PriceDetailView from '@/views/dict/Prices/Control.vue'
import CarTypesView from '@/views/dict/CarTypes/List.vue'
import CarTypeDetailView from '@/views/dict/CarTypes/Control.vue'
import UncleanTypesView from '@/views/dict/UncleanTypes/List.vue'
import UncleanTypeDetailView from '@/views/dict/UncleanTypes/Control.vue'
import UsersView from '@/views/dict/User/List.vue'
import UserDetailView from '@/views/dict/User/Control.vue'
import WashTypesView from '@/views/dict/WashType/List.vue'
import WashTypeDetailView from '@/views/dict/WashType/Control.vue'
import RegistrationView from '@/views/Registration/RegistrationView.vue'
import EmailActiveView from '@/views/EmailActivate/App.vue'
import ResetPasswordView from '@/views/ResetPassword/App.vue'

import { useUserStore } from '@/store/userStore'
import { api } from '@/api'
import { getLocalStorage } from '@/lib/utils/storage'
import { STO_ADMIN, STO_OWNER } from '@/lib/const/role'

const user = getLocalStorage('userData')
const userRole = user?.data?.data?.all_roles[0].name

const isStoUser = userRole === STO_OWNER || userRole === STO_ADMIN

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    name: 'not',
    component: NotFoundView,
  },
  {
    path: '/',
    component: AuthorizedLayout,
    meta: { requiresAuth: true, name: 'Главная' },
    children: [
      { path: '/', component: HomeView },
      {
        path: '/references/additional_services',
        meta: { name: '' },
        component: StubView,
      },
      {
        path: '/references/type_of_wash/:id',
        meta: { name: 'Виды работ' },
        component: WashTypeDetailView,
      },
      {
        path: '/references/type_of_wash',
        meta: { name: 'Виды работ' },
        component: WashTypesView,
      },
      {
        path: '/references/type_of_wash_update',
        meta: { name: 'Виды работ' },
        component: WashTypeDetailView,
      },
      {
        path: '/references/cameras',
        meta: { name: 'Камеры' },
        component: CamerasView,
      },
      {
        path: '/references/camera',
        meta: { name: 'Камера' },
        component: CameraDetailView,
      },
      {
        path: '/references/company_cards',
        meta: { name: 'Банковские карты' },
        component: CompanyCardsView,
      },
      {
        path: '/references/company_card',
        meta: { name: 'Банковская карта' },
        component: CompanyCardDetailView,
      },
      {
        path: '/references/employers',
        meta: { name: 'Сотрудники' },
        component: EmployersView,
      },
      {
        path: '/references/employer',
        meta: { name: 'Сотрудник' },
        component: EmployerDetailView,
      },
      {
        path: '/references/payment_methods',
        meta: { name: 'Способы оплаты' },
        component: PaymentMethodsView,
      },
      {
        path: '/references/payment_method',
        meta: { name: 'Способ оплаты' },
        component: PaymentMethodDetailView,
      },
      {
        path: '/references/posts',
        meta: { name: 'Посты' },
        component: PostsView,
      },
      {
        path: '/references/post',
        meta: { name: 'Пост' },
        component: PostDetailView,
      },
      {
        path: '/references/price_list',
        meta: { name: 'Прайс-лист' },
        component: PricesView,
      },
      {
        path: '/references/price',
        meta: { name: 'Цена' },
        component: PriceDetailView,
      },
      {
        path: '/references/type_of_car',
        meta: { name: 'Тип автомобиля' },
        component: CarTypesView,
      },
      {
        path: '/references/type_of_car_update',
        meta: { name: 'Тип автомобиля' },
        component: CarTypeDetailView,
      },
      {
        path: '/references/unclear_level',
        meta: { name: 'Сложность работ' },
        component: UncleanTypesView,
      },
      {
        path: '/references/unclear_level_update',
        meta: { name: 'Сложность работ' },
        component: UncleanTypeDetailView,
      },
      {
        path: '/references/users',
        meta: { name: 'Пользователи' },
        component: UsersView,
      },
      {
        path: '/references/user',
        meta: { name: 'Пользователь' },
        component: UserDetailView,
      },
      {
        path: '/references/locations',
        meta: { name: 'Локации' },
        component: LocationsView,
      },
      {
        path: '/references/location',
        meta: { name: 'Локация' },
        component: LocationDetailView,
      },
      { path: '/references/loyalty', meta: { name: '' }, component: StubView },
      {
        path: '/companies',
        meta: { name: 'Компании' },
        component: CompaniesView,
      },
      { path: '/photo', meta: { name: 'Фото' }, component: StubView },
      { path: '/help', meta: { name: 'Помощь' }, component: StubView },
      { path: '/cars', meta: { name: 'Автомобили' }, component: StubView },
      { path: '/company', meta: { name: 'Компания' }, component: CompanyView },
      { path: '/checkins', meta: { name: 'Заезды' }, component: CheckinsView },
      {
        path: '/dashboard',
        meta: { name: 'Дашборд' },
        component: DashboardView,
      },
      {
        path: '/report_risks',
        meta: { name: 'Риски' },
        component: RisksView,
      },
    ],
  },
  {
    path: '/',
    component: UnAuthorizedLayout,
    children: [
      { path: '/login', component: Login, name: 'login' },
      {
        path: '/registration',
        component: RegistrationView,
        name: 'registration',
      },
    ],
  },
  {
    path: '/company/email_activate/:id/:hash',
    meta: { name: 'Активация' },
    component: EmailActiveView,
  },
  {
    path: '/reset-password/:token',
    meta: { name: 'Восстановление пароля' },
    component: ResetPasswordView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// Navigation Guard для защищенных маршрутов
router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  const registrationInProgress = localStorage.getItem('registrationStep')

  if (
    registrationInProgress &&
    registrationInProgress !== '4' &&
    to.path !== '/registration'
  ) {
    const confirmLeave = window.confirm('Вы хотите прервать регистрацию?')
    if (confirmLeave) {
      userStore.logout()

      localStorage.removeItem('registrationStep')

      const email = getLocalStorage('register_email')

      api.post(`unregister?email=${email}`)

      localStorage.removeItem('register_email')

      router.push('/login')

      next()
    } else {
      next({ name: 'registration' })
    }
  }

  if (to.meta.requiresAuth && !userStore.isAuthorized) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
